export class DWHFieldMapping {
    constructor(DWHFieldMappingId = '',
                DWHFieldId = '',
                DWHTableStageBridgeId = '',
                Revision = '',
                MappingQuery = '') {
        this.DWHFieldMappingId = DWHFieldMappingId;
        this.DWHFieldId = DWHFieldId;
        this.DWHTableStageBridgeId = DWHTableStageBridgeId;
        this.Revision = Revision;
        this.MappingQuery = MappingQuery;
    }
}








