import HandleResponseService from "./HandleResponseService";

export default class StageDefinitionService {

    static deleteStageDefinition(stagedefinitionid) {
        return axios.post('/api/delete/stagedefinition/' + stagedefinitionid).then(response => {
            return HandleResponseService.handleResponse(response);
        }).catch((error) => {
            return HandleResponseService.handleError(error);
        });
    }
}
