import HandleResponseService from "./HandleResponseService";

export default class DWHTableService {

    static deleteDWHTable(dwhtableid) {
        return axios.post('/api/delete/dwhtable/' + dwhtableid).then(response => {
            return HandleResponseService.handleResponse(response);
        }).catch((error) => {
            return HandleResponseService.handleError(error);
        });
    }

    static saveToAutomatorGenerateLoadProcedures(data) {
        return axios.post('/api/dwhtable/saveToAutomatorGenerateLoadProcedures', data).then(response => {
            return HandleResponseService.handleResponse(response);
        }).catch((error) => {
            return HandleResponseService.handleError(error);
        });
    }

    static saveToAutomatorDropTableGenerateLoadProcedures(data) {
        return axios.post('/api/dwhtable/saveToAutomatorDropTableGenerateLoadProcedures', data).then(response => {
            return HandleResponseService.handleResponse(response);
        }).catch((error) => {
            return HandleResponseService.handleError(error);
        });
    }
}
