export class DWHTable {
    constructor(DWHTableId = '',
                EnvironmentId = '',
                Revision = '',
                TableType = '',
                TableSchema = '',
                TableName = '',
                ViewSchema = '',
                ViewName = '',
                ViewWhere = '') {
        this.DWHTableId = DWHTableId;
        this.EnvironmentId = EnvironmentId;
        this.Revision = Revision;
        this.TableType = TableType;
        this.TableSchema = TableSchema;
        this.TableName = TableName;
        this.ViewSchema = ViewSchema;
        this.ViewName = ViewName;
        this.ViewWhere = ViewWhere;
    }
}








