<template>
    <div class="container">
        <h1>Welcome user   Auth::user()->name</h1>

        <h2>Your environments:</h2>

        <li v-for="environment in environments">{{environment.name}} and your id is ({{environment.id}}) {{environment.validTo}}</li>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                // Somehow get the environments from the database, based on the userId.
                environments: [
                    {
                        id: 1,
                        name: 'Bas',
                        validTo: 'null',
                    },
                    {
                        id: 2,
                        name: 'Daan',
                        validTo: 'null',
                    },
                    {
                        id: 3,
                        name: 'Remco',
                        validTo: 'null',
                    }
                ],
            }
        },
    }
</script>
