export class StoredProcedureModel {
    constructor(DWHTableStageBridgeId = '',
                StoredProcedure = '') {
        this.DWHTableStageBridgeId = DWHTableStageBridgeId;
        this.StoredProcedure = StoredProcedure;
    }
}








