export class Environment {
    constructor(EnvironmentId = '',
        EnvironmentName = '',
        SingleDatabase = '',
        ServerName = '',
        DWHDatabaseName = '',
        DatabaseDriver = '',
        DatabasePort = '',
        LicenseId = '',
        LicenseKey = '',
        LicenseValidTo = '',
    ) {
        this.EnvironmentId = EnvironmentId;
        this.EnvironmentName = EnvironmentName;
        this.SingleDatabase = SingleDatabase;
        this.ServerName = ServerName;
        this.DWHDatabaseName = DWHDatabaseName;
        this.DatabaseDriver = DatabaseDriver;
        this.DatabasePort = DatabasePort;
        this.LicenseId = LicenseId;
        this.LicenseKey = LicenseKey;
        this.LicenseValidTo = LicenseValidTo;
    }
}








