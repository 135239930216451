export class DWHTableStageBridge {
    constructor(DWHTableStageBridgeId = '',
                DWHTableId = '',
                StageDefinitionId = '',
                DWHTableStageBridgeName = '',
                Revision = '',
                LoadType = '',
                PreQuery = '',
                FromQuery = '',
                PostQuery = '',
                ColumnNumber = '') {
            this.DWHTableStageBridgeId = DWHTableStageBridgeId;
            this.DWHTableId = DWHTableId;
            this.StageDefinitionId = StageDefinitionId;
            this.DWHTableStageBridgeName = DWHTableStageBridgeName;
            this.Revision = Revision;
            this.LoadType = LoadType;
            this.PreQuery = PreQuery;
            this.FromQuery = FromQuery;
            this.PostQuery = PostQuery;
            this.ColumnNumber = ColumnNumber;
    }
}








