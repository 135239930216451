<template>
    <div class="container">
        <div class="container">
            <h1>{{this.Environment.EnvironmentName}}</h1>
        </div>

        <br>
        <br>

        <h2>Manage database connection:</h2>

        <table>
            <!--            The link for the useful database stuff: https://stackoverflow.com/questions/58144473/how-to-connect-to-another-database-in-laravel-multiple-database-hundreds    -->
            <tr>
                <td class="col-2">Database host</td>
                <td class="col-4"><input type="text" class="form-control" tabindex="1" v-model="Environment.ServerName" maxlength="200"></td>
                <td class="col-2"></td>
                <td class="col-2">Database connection:</td>
                <td v-if="isDatabaseActive()" class="col-2 text-green" >{{this.databaseStatus}}</td>
                <td v-else class="col-2 text-red">{{this.databaseStatus}}</td>
            </tr>
            <tr>
                <td class="col-2">Database name</td>
                <td class="col-4"><input type="text" class="form-control" tabindex="1" v-model="Environment.DWHDatabaseName" maxlength="200"></td>
                <td class="col-2"></td>
                <td class="col-2">Username:</td>
                <td class="col-2"><input type="text" class="form-control" tabindex="2" v-model="username"></td>
            </tr>
            <tr>
                <td class="col-2">Database driver</td>
                <td class="col-4"><input type="text" class="form-control" tabindex="1" v-model="Environment.DatabaseDriver" maxlength="200"></td>
                <td class="col-2"></td>
                <td class="col-2">Password:</td>
                <td class="col-2"><input type="password" class="form-control" tabindex="2" v-model="password"></td>
            </tr>
            <tr>
                <td class="col-2">Database port</td>
                <td class="col-4"><input type="number" class="form-control" tabindex="1" v-model="Environment.DatabasePort"></td>
                <td class="col-2"></td>
                <td class="col-2">
                    <button class="btn btn-secondary" v-on:click="updateDWHStatus(true)">Refresh Status</button>
                </td>
                <td class="col-2">
                    <button class="btn btn-secondary" tabindex="2" v-on:click="loginToDatabase()">Login</button>
                </td>
            </tr>
            <tr>
                <td><button class="btn btn-secondary" tabindex="1" v-on:click="saveEnvironment()">Save settings</button></td>
            </tr>
        </table>
        <br>
        <br>
        <br>
        <br>
        <br>

        <div v-show="!this.connectionActive">
            <h2 class="text-center text-red">You must be connected to the DWH to view tables and make changes.</h2>
        </div>

        <div v-show="this.connectionActive" class="container">

            <h2>Stages</h2>
            <table class="table table-striped">
                <tr>
                    <th class="col-6"></th>
                    <th class="col-2">Manage</th>
                </tr>
                <tr v-for="stage in Stages">
                    <td class="col-6"><b>{{stage.StageDefinitionId}}: {{stage.StageName}}</b></td>
                    <td class="col-2">
                        <a class="btn btn-primary btn-sm" :href="'/user/stages/' + stage.StageDefinitionId + '/edit'" tabindex="3">
                            <i class="far fa-edit"></i>
                        </a>
                        <button class="btn btn-danger btn-sm delete" tabindex="4" v-on:click="deleteStageDefinition(stage.StageDefinitionId, stage.StageName)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </table>
            <a :href="'/user/stages/create/' + environment.EnvironmentId" tabindex="5" class="btn btn-secondary">Add new stage</a>
            <br>
            <br>
            <br>
            <br>
            <br>
            <h2>DWHTables</h2>
            <br>
            <table class="table table-striped">
                <tr>
                    <th class="col-6">Dims</th>
                    <th class="col-2">Manage</th>
                </tr>
                <tr v-for="DWHTable in DimDWHTables">
                    <td class="col-6"><b>{{DWHTable.TableType}}: {{DWHTable.TableName}}</b></td>
                    <td class="col-2">
                        <a class="btn btn-primary btn-sm" :href="'/user/dwhtables/' + environment.EnvironmentId + '/' + DWHTable.DWHTableId + '/edit'" tabindex="6">
                            <i class="far fa-edit"></i>
                        </a>
                        <button class="btn btn-danger btn-sm delete" tabindex="7" v-on:click="deleteDWHTable(DWHTable.DWHTableId, DWHTable.TableName)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </table>
            <br>
            <table class="table table-striped">
                <tr>
                    <th class="col-6">Facts</th>
                    <th class="col-2">Manage</th>
                </tr>
                <tr v-for="DWHTable in FactDWHTables">
                    <td class="col-6"><b>{{DWHTable.TableType}}: {{DWHTable.TableName}}</b></td>
                    <td class="col-2">
                        <a class="btn btn-primary btn-sm" :href="'/user/dwhtables/' + environment.EnvironmentId + '/' + DWHTable.DWHTableId + '/edit'" tabindex="6">
                            <i class="far fa-edit"></i>
                        </a>
                        <button class="btn btn-danger btn-sm delete" tabindex="7" v-on:click="deleteDWHTable(DWHTable.DWHTableId, DWHTable.TableName)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </table>
            <a :href="'/user/dwhtables/create/' + environment.EnvironmentId" tabindex="8" class="btn btn-secondary">Add new DWHTable</a>
            <br>
        </div>
    </div>
</template>


<script>
import {StageDefinition} from "../models/StageDefinition.js";
import {DWHTable} from "../models/DWHTable.js";
import {DWHTableStageBridge} from "../models/DWHTableStageBridge.js";
import {Environment} from "../models/Environment.js";
import EnvironmentService from "../services/EnvironmentService";
import StageDefinitionService from "../services/StageDefinitionService";
import DWHTableService from "../services/DWHTableService";
import {StageDeleteModel} from "../models/StageDeleteModel";

export default {
    data() {
        return {
            Stages: [],             // A list that contains all Stages.
            DWHTables: [],          // A list that contains all DWHTables.
            DimDWHTables: [],       // A list that contains only all Dim DWHtables.
            FactDWHTables: [],      // A list that contains only all Fact DWHTables.
            Environment: [],
            DWHTableStageBridges: [],// A list that contains all DWHTableStageBridges

            AffectedDWHTableStageBridges: [], // A list that shows all stages affected on the deletion of a DWHTable.
            connectionActive: false, // A boolean to keep track if the database connection is active or not

            username: '',
            password: '',
            databaseStatus: 'Inactive',
            debug: true,        // Debug mode, logs (more) messages to the browser console.
        }
    },
    props: [
        'environment',
        'stages',
        'dwhtables',
        'dwhtablestagebridges',
        'isdwhactive'
    ],

    mounted() {
        console.log('We zijn gemount');

        this.Environment = new Environment(
            parseInt(this.environment.EnvironmentId),
                     this.environment.EnvironmentName,
                     this.environment.SingleDatabase,
                     this.environment.ServerName,
                     this.environment.DWHDatabaseName,
                     this.environment.DatabaseDriver,
            parseInt(this.environment.DatabasePort),
            '',
            '',
            '',
        );

        this.Stages = this.stages.map(item => {
            return new StageDefinition(
                parseInt(item.StageDefinitionId),
                parseInt(item.EnvironmentId),
                parseInt(item.Revision),
                item.StageName,
                item.SourceType,
            );
        });

        this.DWHTables = this.dwhtables.map(item => {
            return new DWHTable(
                parseInt(item.DWHTableId),
                parseInt(item.EnvironmentId),
                parseInt(item.Revision),
                item.TableType,
                item.TableSchema,
                item.TableName,
            );
        });

        this.DWHTableStageBridges = this.dwhtablestagebridges.map(item => {
            return new DWHTableStageBridge(
                parseInt(item.DWHTableStageBridgeId),
                parseInt(item.DWHTableId),
                parseInt(item.StageDefinitionId),
                item.DWHTableStageBridgeName,
                parseInt(item.Revision),
                item.LoadType,
                item.PreQuery,
                item.FromQuery,
                item.PostQuery,
                parseInt(item.ColumnNumber),
            );
        });

        this.splitDimFact();
        this.sortAll();

        this.logger(this.DWHTableStageBridges);

        this.connectionActive = this.isdwhactive;
        if(this.isdwhactive) this.databaseStatus = 'Active';
    },

    methods: {

        /**
         * Splits the DWHTables list into two lists, all DWHTables with schematype Dim go to
         * the DimDWHTables list, while all Fact DWHTables go to the FactDWHTables list.
         */
        splitDimFact(){
            this.DimDWHTables = this.DWHTables.filter(obj => {
                return obj.TableType === 'Dim';
            });

            this.FactDWHTables = this.DWHTables.filter(obj => {
                return obj.TableType === 'Fact';
            });
        },

        /***
         * Delete a stage definition.
         * If a stage definition is used in DWHTableStageBridges, the user will be notified of all DWHTables where
         * such DWHTableStageBridges exist, and can still abort the action.
         * @param StageDefinitionId
         * @param StageName
         */
        deleteStageDefinition(StageDefinitionId, StageName){
            this.AffectedDWHTableStageBridges = [];
            this.DWHTableStageBridges.forEach(DWHTableStageBridge => {
                if(DWHTableStageBridge.StageDefinitionId === StageDefinitionId){
                    this.AffectedDWHTableStageBridges.push(new StageDeleteModel(
                        parseInt(DWHTableStageBridge.DWHTableId),
                        this.getDWHTableName(parseInt(DWHTableStageBridge.DWHTableId)),
                        DWHTableStageBridge.DWHTableStageBridgeName,
                    ));
                }
            });

            this.sortAffectedDWHTableStageBridges();

            /**
             * Create a variable to store the warning message. Based on the result of if DWHTables will be affected,
             * the warning message will vary.
             * @type {string}
             */
            var warningMessage = '';
            if(this.AffectedDWHTableStageBridges.length > 0){
                warningMessage = "Do you really want to delete " + StageName + "? \nThis will alter the following tables: \n\n";
                this.AffectedDWHTableStageBridges.forEach(affectedDWHTSB => warningMessage = warningMessage + "    - " + affectedDWHTSB.DWHTableName + " : " + affectedDWHTSB.DWHTableStageBridgeName + "\n");
            }
            else warningMessage = "Do you really want to delete " + StageName + "? \nThis will alter no tables. \n\n";

            if(confirm(warningMessage)){
                StageDefinitionService.deleteStageDefinition(StageDefinitionId).then(response => {
                    if(this.handleResult("Refresh all stages after delete", response)){
                        this.Stages = response.data.Data.map(item => {
                            return new StageDefinition(
                                parseInt(item.StageDefinitionId),
                                parseInt(item.EnvironmentId),
                                parseInt(item.Revision),
                                item.StageName,
                                item.SourceType,
                            );
                        });
                        this.sortAll();
                    }
                });
            }
        },

        /**
         * Deletes a DWHTable and all elements belonging to it. Will show a pop-up message to confirm its deletion.
         * @param DWHTableId
         * @param DWHTableName
         */
        deleteDWHTable(DWHTableId, DWHTableName){
            if(confirm("Do you really want to delete " + DWHTableName + "?")){
                DWHTableService.deleteDWHTable(DWHTableId).then(response => {
                    if(this.handleResult("deleteDWHTable", response)){
                        this.createDWHTables(response.data.Data);
                        this.splitDimFact();
                        this.sortAll();

                        // Delete the TSB's belonging to the DWHTable from memory within the page as they no longer exist.
                        this.DWHTableStageBridges = this.DWHTableStageBridges.filter(item => item.DWHTableId !== DWHTableId);
                    }
                });
            }
        },

        /**
         * Returns the DWHTableName if the DWHTable is known.
         * @param DWHTableId
         * @returns {string}
         */
        getDWHTableName(DWHTableId){
            const DWHTable = this.DWHTables.filter(obj => {
                return obj.DWHTableId === DWHTableId;
            });
            return DWHTable[0].TableSchema + '.' + DWHTable[0].TableName;
        },

        /**
         * Sorts all stages alphabetically.
         */
        sortAffectedDWHTableStageBridges(){
            this.AffectedDWHTableStageBridges.sort(function(a, b) {
                if(Math.abs(a.DWHTableId) === Math.abs(b.DWHTableId)){
                    return ('' + a.DWHTableStageBridgeName).localeCompare(b.DWHTableStageBridgeName);
                }
                else return Math.abs(a.DWHTableId) - Math.abs(b.DWHTableId);
            });
        },

        /**
         * Sorts all stages and DWHTables.
         */
        sortAll(){
            this.Stages.sort(function(a, b) {
                return Math.abs(a.StageDefinitionId) - Math.abs(b.StageDefinitionId);
            });

            this.DWHTables.sort(function(a, b) {
                return Math.abs(a.DWHTableId) - Math.abs(b.DWHTableId);
            });

            this.DimDWHTables.sort(function(a, b) {
                return Math.abs(a.DWHTableId) - Math.abs(b.DWHTableId);
            });

            this.FactDWHTables.sort(function(a, b) {
                return Math.abs(a.DWHTableId) - Math.abs(b.DWHTableId);
            });

            this.DWHTableStageBridges.sort(function(a, b) {
                return a.DWHTableStageBridgeName > b.DWHTableStageBridgeName;
            });
        },

        /**
         * Saves the environment to the Automator.
         */
        saveEnvironment(){
            EnvironmentService.storeEnvironment(this.Environment).then(response => {
                if(this.handleResult("SaveEnvironment" ,response)){
                    this.notifyUser("Successfully saved!");
                } else {
                    this.notifyUser("Something went wrong.. Please try refreshing the page.");
                }
                // Update the DWHStatus to reflect the attempt. Do not alert user.
                this.updateDWHStatus(true);
            });
        },

        loginToDatabase(){
            EnvironmentService.logIntoDWH([this.username, this.password, this.environment.EnvironmentId]).then(response => {
                this.handleResult("Login to DWH", response);
                // Update the DWHStatus to reflect the attempt.
                this.updateDWHStatus(true);
            });
        },

        /**
         * Updates the DWH connection status.
         * @param notifyUser
         */
        updateDWHStatus(notifyUser){
            this.databaseStatus='Loading...';
            EnvironmentService.isLoggedIntoDWH(this.environment.EnvironmentId).then(response => {
                // In some cases, the user doesn't need a notification when there is no connection.
                if(notifyUser){
                    if(this.handleResult("Login to DWH", response)){
                        // Update the DWHStatus to reflect the attempt.
                        this.setDWHActive();

                    } else {
                        this.setDWHInactive();
                    }
                }
                else {
                    if(response.data.Success){
                        // Update the DWHStatus to reflect the attempt.
                        this.setDWHActive();
                    } else {
                        this.setDWHInactive();
                    }
                }
            });
        },

        /**
         * Function that creates a new list of DWHTables based on the stuff it receives from the server.
         * @param original
         */
        createDWHTables(original) {
            this.DWHTables = original.map(item => {
                return new DWHTable(
                    parseInt(item.DWHTableId),
                    parseInt(item.EnvironmentId),
                    parseInt(item.Revision),
                    item.TableType,
                    item.TableSchema,
                    item.TableName,
                );
            });
        },

        /**
         * Notifies the user with a custom message in a pop-up box.
         * @param message
         */
        notifyUser(message){
            alert(message);
            // let user know the message
        },

        /**
         * Shows if the DWH is currently connected to.
         * @returns {boolean}
         */
        isDatabaseActive(){
            return this.databaseStatus === "Active";
        },

        /**
         * Sets the variables for the DWH connection to true/active
         */
        setDWHActive(){
            this.databaseStatus='Active';
            this.connectionActive = true;
        },

        /**
         * Sets the variables for the DWH connection to false/inactive
         */
        setDWHInactive(){
            this.databaseStatus='Inactive';
            this.connectionActive = false;
        },

        /**
         *  Returns true if the result was valid, false if not.
         */
        handleResult(actionName, response){
            this.logger("The request for " + actionName + " was (1 = Success, 0 = Failed): " + response.data.Success);
            if(!response.data.Success) {
                this.notifyUser("Something went wrong: " + response.data.Message);
                this.logger("ERROR: " + response.data.Message);
            }
            return response.data.Success;
        },

        /**
         * A custom logger that only shows logs if the global debug variable is set to true.
         * @param message
         */
        logger(message){
            if(this.debug){
                console.log(message);
            }
        },
    }
}
</script>

<style>
.text-red{
    color: #ff0000;
}

.text-green{
    color: #039803;
}
</style>
