export class CommunicationPackage {
    constructor(Success = '',
                Message = '',
                Data = '',) {
        this.Success = Success;
        this.Message = Message;
        this.Data = Data;
    }
}








