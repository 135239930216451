<template>
    <div>
        <div class="container">
            <a :href="'/user/environments/' + environment.EnvironmentId + '/edit'" class="btn btn-secondary">Back to {{ this.environmentName }}</a>
            <br>
            <br>
            <h1 v-if="isEdit(this.isedit)">Configure {{ StaticTableSchemaTableName }}.</h1>
            <h1 v-else>Add new DWHTable</h1>
            <br>

            <table class="table table-striped">
                <tr>
                    <td class="TableLabels">
                        <b><label>TableType</label></b>
                    </td>
                    <td class="TableFields">
                        <select v-if="this.DWHTable.TableType == 'Dim'"
                                class="btn btn-outline-secondary dropdown-toggle" @change="changeTableType($event)">
                            <option value="Dim" selected="selected" >Dim</option>
                            <option value="Fact"                    >Fact</option>
                        </select>
                        <select v-else
                                class="btn btn-outline-secondary dropdown-toggle" @change="changeTableType($event)">
                            <option value="Dim"                     >Dim</option>
                            <option value="Fact" selected="selected">Fact</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td class="TableLabels">
                        <b><label>TableSchema</label></b>
                    </td>
                    <td class="TableFields">
                        <input v-if="!isDWHTableNameUnique()"
                               type="text"
                               class="form-control text-red"
                               v-model="DWHTable.TableSchema"
                               maxlength="200"
                               autofocus>
                        <input v-else
                               type="text"
                               class="form-control"
                               v-model="DWHTable.TableSchema"
                               maxlength="200"
                               autofocus>
                    </td>
                    <td class="ViewLabels">
                        <b><label>ViewSchema</label></b>
                    </td>
                    <td class="ViewFields">
                        <input v-if="!isViewNameUnique()"
                               type="text"
                               class="form-control text-red"
                               v-model="DWHTable.ViewSchema"
                               maxlength="200"
                               autofocus>
                        <input v-else
                               type="text"
                               class="form-control"
                               v-model="DWHTable.ViewSchema"
                               maxlength="200"
                               autofocus>
                    </td>
                </tr>
                <tr>
                    <td class="TableLabels">
                        <b><label>TableName</label></b>
                    </td>
                    <td class="TableFields">
                        <input v-if="!isDWHTableNameUnique()"
                               type="text"
                               class="form-control text-red"
                               v-model="DWHTable.TableName"
                               maxlength="200"
                               autofocus>
                        <small v-if="!isDWHTableNameUnique()"
                               class="form-text text-muted">Combination TableSchema + TableName already exists within
                            this Environment. This could also already exist as ViewSchema + ViewName.</small>
                        <input v-else
                               type="text"
                               class="form-control"
                               v-model="DWHTable.TableName"
                               maxlength="200"
                               autofocus>
                    </td>
                    <td class="ViewLabels">
                        <b><label>ViewName</label></b>
                    </td>
                    <td class="ViewFields">
                        <input v-if="!isViewNameUnique()"
                               type="text"
                               class="form-control text-red"
                               v-model="DWHTable.ViewName"
                               maxlength="200">
                        <small v-if="!isViewNameUnique()"
                               class="form-text text-muted">Combination ViewSchema + ViewName already exists within
                            this Environment. This could also already exist as TableSchema + TableName.</small>
                        <input v-else
                               type="text"
                               class="form-control"
                               v-model="DWHTable.ViewName"
                               maxlength="200">
                    </td>
                </tr>
                <tr>
                    <td class="TableLabels"></td>
                    <td class="TableFields"></td>
                    <td class="ViewLabels">
                        <b><label>ViewWhere</label></b>
                    </td>
                    <td class="ViewFields">
                        <textarea class="form-control" v-model="DWHTable.ViewWhere"></textarea>
                    </td>
                </tr>
            </table>
            <br>
        </div>

        <div>
            <table>
<!--                            <table class="table-responsive"> THIS ONE SETS THE TABLE TO A SMALLER WINDOW SO THE ENTIRE PAGE DOESN'T SCROLL-->
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"></td>
                    <td class="fieldNameColumn"></td>
                    <td class="dataTypeColumn"></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"></td>
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
                        <button @click="removeTableStageBridge(DWHTableStageBridge.DWHTableStageBridgeId)" class="btn btn-sm" tabindex="-1"><i class="fas fa-trash"></i></button>
                        <button @click="columnLeft(DWHTableStageBridge.DWHTableStageBridgeId)" class="btn btn-sm" tabindex="-1"><i class="fas fa-angle-left"></i></button>
                        <button @click="columnRight(DWHTableStageBridge.DWHTableStageBridgeId)" class="btn btn-sm" tabindex="-1"><i class="fas fa-angle-right"></i></button>
                    </td>
                    <td class="col-1"></td>
                </tr>
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"></td>
                    <td class="fieldNameColumn"></td>
                    <td class="dataTypeColumn text-red"><h3>{{this.updateMessage}}</h3></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"><b>Column</b></td>
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
                        <input type="number" class="form-control" tabindex="-1" v-model="DWHTableStageBridge.ColumnNumber" readonly>
                    </td>
                    <td class="fieldMappingColumn">
                        Insert stage:
                    </td>
                </tr>
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"></td>
                    <td class="fieldNameColumn"></td>
                    <td class="dataTypeColumn"></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"><b>StageName</b></td>
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
                        <textarea
                            disabled
                            type="text"
                            class="form-control"
                            rows="1"
                            :tabindex="-1"
                            v-model="getStage(DWHTableStageBridge.StageDefinitionId).StageName"
                        ></textarea>
                    </td>
                    <td class="fieldMappingColumn">
                        <select name="stages" id="stages" class="btn btn-outline-secondary dropdown-toggle" @change="addDWHTableStageBridge($event)">
                            <option value="-1"></option>
                            <option v-for="Stage in this.Stages" v-bind:value="Stage.StageDefinitionId"> {{ Stage.StageName }}</option>
                        </select>
                    </td>
                </tr>
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"></td>
                    <td class="fieldNameColumn">
                        <button class="btn btn-secondary col-12"
                                :disabled='this.sendingData || !this.editMode'
                                tabindex="1"
                                @click="saveGenerate()"
                        >
                            Generate procedures
                        </button>
                    </td>
                    <td class="dataTypeColumn"></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"><b>StoredProcedure Name</b></td>
                    <!--                <td v-for="DWHTableStageBridge in this.DWHTableStageBridges">{{ getStage(DWHTableStageBridge.StageDefinitionId) }}</td> This one does not give errors oddly enough-->
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
                        <textarea
                            type="text"
                            class="form-control"
                            rows="1"
                            :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                            v-model="DWHTableStageBridge.DWHTableStageBridgeName"
                        ></textarea>
                    </td>
                </tr>
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"></td>
                    <td class="fieldNameColumn">
                        <button class="btn btn-secondary col-12"
                                :disabled='this.sendingData'
                                tabindex="1"
                                @click="saveDropGenerate()"
                        >
                            (Drop &) Create table and generate procedures
                        </button>
                    </td>
                    <td class="dataTypeColumn"></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"><b>LoadType</b></td>
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
                        <select v-if="DWHTableStageBridge.LoadType == 'Delete'" :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                                class="btn btn-outline-secondary dropdown-toggle" @change="changeLoadType($event)">
                            <option :value="'Merge, ' + DWHTableStageBridge.DWHTableStageBridgeId"                              >Merge</option>
                            <option :value="'Truncate, ' + DWHTableStageBridge.DWHTableStageBridgeId"                           >Truncate</option>
                            <option :value="'Delete, ' + DWHTableStageBridge.DWHTableStageBridgeId"     selected="selected"     >Delete</option>
                        </select>
                        <select v-else-if="DWHTableStageBridge.LoadType == 'Truncate'" :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                                class="btn btn-outline-secondary dropdown-toggle" @change="changeLoadType($event)">
                            <option :value="'Merge, ' + DWHTableStageBridge.DWHTableStageBridgeId"                              >Merge</option>
                            <option :value="'Truncate, ' + DWHTableStageBridge.DWHTableStageBridgeId"   selected="selected"     >Truncate</option>
                            <option :value="'Delete, ' + DWHTableStageBridge.DWHTableStageBridgeId"                             >Delete</option>
                        </select>
                        <select v-else :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                                class="btn btn-outline-secondary dropdown-toggle" @change="changeLoadType($event)">
                            <option :value="'Merge, ' + DWHTableStageBridge.DWHTableStageBridgeId"      selected="selected"     >Merge</option>
                            <option :value="'Truncate, ' + DWHTableStageBridge.DWHTableStageBridgeId"                           >Truncate</option>
                            <option :value="'Delete, ' + DWHTableStageBridge.DWHTableStageBridgeId"                             >Delete</option>
                        </select>
                    </td>
                </tr>
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"></td>
                    <td class="fieldNameColumn"></td>
                    <td class="dataTypeColumn"></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"><b>PreQuery</b></td>
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
                        <!--                        <input type="text" class="form-control" v-model="DWHTableStageBridge.PreQuery">-->
                        <textarea class="form-control" rows="3" :tabindex="5 + DWHTableStageBridge.ColumnNumber" v-model="DWHTableStageBridge.PreQuery"></textarea>
                    </td>
                </tr>

                <!--                DWHField STUFF AND DWHFieldMappings HERE-->

                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"><b>(Re)Move row</b></td>
                    <td class="rowNumberColumn"><b>Row</b></td>
                    <td class="fieldNameColumn"><b>FieldName</b></td>
                    <td class="dataTypeColumn"><b>Data Type</b></td>
                    <td class="keyColumn"><b>Key</b></td>
                    <td class="sCD2Column"><b>SCD2</b></td>
                    <td class="inViewColumn"><b>In View</b></td>
                </tr>
                <draggable  :list="DWHFields" handle=".handle" @end="moveRow">
                    <tr class="row flex-row flex-nowrap" v-for="DWHField in this.DWHFields">
                        <td class="leftCol handle" style="text-align: center; vertical-align: middle;">
                            <button @click="removeRow(DWHField.DWHFieldId)" class="btn btn-sm" tabindex="-1"><i class="fas fa-trash"></i></button>
                            <i class="fas fa-arrows-alt"></i>
                        </td>
                        <td class="rowNumberColumn"><input type="text" class="form-control" tabindex="-1" v-model="DWHField.RowNumber" readonly></td> <!-- Change DWHField.Rownumber for DWHField.Id or sth-->

                        <td v-if="DWHField.RowNumber % 2 === 1" class="fieldNameColumn">
                            <input type="text" class="form-control unevenrows" tabindex="2" v-model="DWHField.FieldName" maxlength="200">
                        </td>
                        <td v-else class="fieldNameColumn">
                            <input type="text" class="form-control" tabindex="2" v-model="DWHField.FieldName" maxlength="200">
                        </td>

                        <td v-if="DWHField.RowNumber % 2 === 1" class="dataTypeColumn">
                            <input type="text" class="form-control unevenrows" tabindex="3" v-model="DWHField.DataType" maxlength="200">
                        </td>
                        <td v-else class="dataTypeColumn">
                            <input type="text" class="form-control" tabindex="3" v-model="DWHField.DataType" maxlength="200">
                        </td>

                        <td class="keyColumn">
                            <select
                                class="btn btn-outline-secondary dropdown-toggle"
                                v-model="DWHField.Key"
                                tabindex="4" @change="changeKey($event, DWHField.DWHFieldId)"

                            >
                                <option disabled value="null">-</option>
                                <option :value="''"                            >-</option>
                                <option :value="'PK'"                          >PK</option>
                                <option :value="'FK'"                          >FK</option>
                                <option :value="'BK'"                          >BK</option>
                            </select>
                        </td>
                        <td class="sCD2Column" style="text-align: left;">
                            <input
                                v-if="hasKey(DWHField.Key)"
                                disabled
                                type="checkbox"
                                v-model="DWHField.SCD2"
                                true-value="1"
                                false-value="0"
                                tabindex="4"
                            >
                            <input
                                v-else
                                type="checkbox"
                                v-model="DWHField.SCD2"
                                true-value="1"
                                false-value="0"
                                tabindex="4"
                            >
                        </td>
                        <td class="inViewColumn" style="text-align: left;">
                            <input
                                type="checkbox"
                                v-model="DWHField.FieldIncludedInView"
                                true-value="1"
                                false-value="0"
                                tabindex="4"
                            >
                        </td>
                        <td class="middleLabelColumn"></td>
                        <!--                <td v-for="DWHTableStageBridge in DWHTableStageBridges">{{ getFieldMapping(DWHTableStageBridge.StageDefinitionId, DWHField.DWHFieldId) }}</td> This one does not give errors oddly enough-->
                        <td class="fieldMappingColumn" v-for="DWHTableStageBridge in DWHTableStageBridges">
                            <textarea
                                v-if="DWHField.Key === 'PK' && DWHField.RowNumber % 2 === 1"
                                disabled
                                type="text"
                                class="form-control unevenrows"
                                rows="1"
                                :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                                v-model="getFieldMapping(DWHTableStageBridge.DWHTableStageBridgeId, DWHField.DWHFieldId).MappingQuery"
                            ></textarea>
                            <textarea
                                v-else-if="DWHField.RowNumber % 2 === 1"
                                type="text"
                                class="form-control unevenrows"
                                rows="1"
                                :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                                v-model="getFieldMapping(DWHTableStageBridge.DWHTableStageBridgeId, DWHField.DWHFieldId).MappingQuery"
                            ></textarea>

                            <textarea
                                v-else-if="DWHField.Key === 'PK'"
                                disabled
                                type="text"
                                class="form-control"
                                rows="1"
                                :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                                v-model="getFieldMapping(DWHTableStageBridge.DWHTableStageBridgeId, DWHField.DWHFieldId).MappingQuery"
                            ></textarea>
                            <textarea
                                v-else
                                type="text"
                                class="form-control"
                                rows="1"
                                :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                                v-model="getFieldMapping(DWHTableStageBridge.DWHTableStageBridgeId, DWHField.DWHFieldId).MappingQuery"
                            ></textarea>
                        </td>
                    </tr>
                </draggable>
                <br>
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"><button type="button" class="btn btn-outline-danger" @click="addRow">Add field</button></td>
                    <td class="fieldNameColumn"></td>
                    <td class="dataTypeColumn"></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"><b>FromQuery</b></td>
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
                        <!--                        <input type="text" class="form-control" v-model="DWHTableStageBridge.FromQuery">-->
                        <textarea class="form-control" rows="3" :tabindex="5 + DWHTableStageBridge.ColumnNumber" v-model="DWHTableStageBridge.FromQuery"></textarea>
                    </td>
                </tr>
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"></td>
                    <td class="fieldNameColumn"></td>
                    <td class="dataTypeColumn"></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"><b>PostQuery</b></td>
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
                        <!--                        <input type="text" class="form-control" v-model="DWHTableStageBridge.PostQuery">-->
                        <textarea class="form-control" rows="3" :tabindex="5 + DWHTableStageBridge.ColumnNumber" v-model="DWHTableStageBridge.PostQuery"></textarea>
                    </td>
                </tr>
                <tr class="row flex-row flex-nowrap">
                    <td class="leftCol"></td>
                    <td class="rowNumberColumn"></td>
                    <td class="fieldNameColumn"></td>
                    <td class="dataTypeColumn"></td>
                    <td class="keyColumn"></td>
                    <td class="sCD2Column"></td>
                    <td class="inViewColumn"></td>
                    <td class="middleLabelColumn"><b>StoredProc</b></td>
                    <td class="fieldMappingColumn" v-for="DWHTableStageBridge in this.DWHTableStageBridges">
<!--                        <textarea class="form-control" rows="3"-->
<!--                                  :tabindex="5 + DWHTableStageBridge.ColumnNumber"-->
<!--                                  v-model="TSBStoredProcedureMap.get(DWHTableStageBridge.DWHTableStageBridgeId)"-->
<!--                        >-->
<!--                        </textarea>-->
                        <textarea
                            v-if="DWHTSBerrorIDs.includes(DWHTableStageBridge.DWHTableStageBridgeId)"
                            type="text"
                            class="form-control text-red"
                            :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                            v-model="getStoredProcedure(DWHTableStageBridge.DWHTableStageBridgeId).StoredProcedure"
                            readonly
                        >
                        </textarea>

                        <textarea
                            v-else
                            type="text"
                            class="form-control"
                            :tabindex="5 + DWHTableStageBridge.ColumnNumber"
                            v-model="getStoredProcedure(DWHTableStageBridge.DWHTableStageBridgeId).StoredProcedure"
                            readonly
                        >
                        </textarea>
                    </td>
                </tr>
            </table>
        </div>
        <br>
        <br>
        <div class="container">
            <pre>{{this.errorMessage}}</pre>
        </div>
        <!--        <pre>{{trimErrorMessage(this.errorMessage)}}</pre>-->

        <!--        <p v-if="this.errorMessage != ''" class="text-center text-red"> This message might be displayed improperly.-->
        <!--            In this case, a scrollbar should appear at the bottom of the page. Please check this before debugging.-->
        <!--        </p>-->
<!--        <pre>{{this.DWHTableStageBridges}}</pre>-->
<!--        <pre>{{this.DWHFields}}</pre>-->
    </div>
</template>

<script>
import {DWHTable} from "../models/DWHTable.js";
import {DWHField} from "../models/DWHField.js";
import {StageDefinition} from "../models/StageDefinition.js";
import {DWHFieldMapping} from "../models/DWHFieldMapping.js";
import {DWHTableStageBridge} from "../models/DWHTableStageBridge.js";
import {StoredProcedureModel} from "../models/StoredProcedureModel";
import EnvironmentService from "../services/EnvironmentService";
import DWHTableService from "../services/DWHTableService";
import {CommunicationPackage} from "../models/CommunicationPackage.js";
import draggable from "vuedraggable";

export default {
    name: "handle",
    display: "Handle",
    instruction: "Drag using the handle icon",
    order: 5,
    components: {
        draggable
    },
    data() {
        return {
            // Simple variables
            environmentName: [],                // The environmentName is saved to display at the top left 'back' button
            StaticTableSchemaTableName: [],     // Purely used for styling.
            lowestDWHFieldsInteger: [],         // Saves the lowest DWHFieldId integer. This helps when adding new DWHFields etc.
                                                // to keep unique combinations of ID's.
            lowestDWHStageBridgeInteger: [],    // Saves the lowest DWHStadeBridge integer. This helps when adding new Stages
            highestRowNumber: 0,                // Saves the highest row number, allows for easily adding new rows.
            highestColumnNumber: -1,             // Saves the highest column number, allows for easily adding new columns.
            updateMessage: '',                  // A variable that holds an update message for the client
            debug: true,                        // A variable that enables or disables all comments
            editMode: false,                    // A variable that takes the role of the isedit variable issued as prop.
                                                // Done because the program gets mad if I adjust isedit, so I will adjust
                                                // editMode instead.\
            errorMessage: '',                   // An error message for failed SQL queries on the DWH.
            sendingData: false,                 // A variable that keeps track of if the website is sending data to the
                                                // server to save.
            DWHTSBerrorIDs: [],                // A list of all DWHTableStageBridgeIds that have errors in their SPs

            // Lists of objects
            DWHTable: [],                       // Holds the DWHTable we're editing
            DWHFields: [],                      // Holds all the DWHFields related to this table
            DWHTableStageBridges: [],           // Holds all the DWHTableStageBridges
            DWHFieldMappings: [],               // DWHFieldMappings correlate between DWHFields and DWHTableStageBridges
            Stages: [],                         // Holds all stages of the environment. Used for the 'select stage' dropdown.
            removedDWHFields: [],               // A list that keeps track of which DWHFields are removed.
            removedDWHTableStageBridges: [],    // A list that keeps track of which DWHTableStageBridges are removed.
            updateModelsDWHFieldIds: [],            // A list that keeps track of which negative DWHFieldIds belong to genuine DWHFieldIds
            updateModelsDWHTableStageBridgeIds: [], // A list that keeps track of which negative DWHTableStageBridgeIds belong to genuine DWHTableStageBridgeIds
            StoredProcedures: [],               // A map that holds the stored proc for every DWHTSB.
        }
    },
    props: ['environment',
        'stages',
        'isedit',
        'dwhtables',
        'dwhtable',
        'dwhtablestagebridges',
        'dwhfields',
        'dwhfieldmappings',
    ],

    mounted() {
        console.log('We zijn gemount');

        // Local function within mounted, as everything within mounted does not have access to the regular methods.
        function isEditLocalScope(edit){
            return edit > 0;
        }
        this.editMode = isEditLocalScope(this.isedit);

        this.environmentName = this.environment.EnvironmentName;
        this.Stages = this.stages.map(item => {
            return new StageDefinition(
                parseInt(item.StageDefinitionId),
                parseInt(item.EnvironmentId),
                parseInt(item.Revision),
                item.StageName,
                item.SourceType,
            );
        });
        this.Stages.sort(function(a, b) {                   // Sort all stage definitions so they always
            return Math.abs(a.StageDefinitionId) - Math.abs(b.StageDefinitionId);   // appear the same way
        });

        this.DWHTables = this.dwhtables;

        /**
         * This part is only if the user is trying to edit an already existing DWHTable.
         * The data collected for this part is:
         *      The DWHTable
         *      The DWHFields
         *      The DWHTableStageBridges
         *      The DWHFieldMappings
         */
        if(isEditLocalScope(this.isedit)){
            // Set the variables in the correct position
            this.lowestDWHFieldsInteger = -1;
            this.logger("Creating DWHTable");
            this.createDWHTable(this.dwhtable);
            // Set the OldTableSchemaTableName variable for the header of the page.
            this.StaticTableSchemaTableName = this.DWHTable.TableSchema + '.' + this.DWHTable.TableName;
            // Get the DWHFields
            this.logger("Creating DWHFields");
            this.createDWHFields(this.dwhfields);
            // Map all DWHFieldMappings to an actual DWHFieldMapping.
            this.logger("Creating FieldMappings");
            this.createDWHFieldMappings(this.dwhfieldmappings);
            // Get all Stage-ID's that have a relation to the DWHTableId
            this.logger("Creating DWHTSBs");
            this.createDWHTableStageBridges(this.dwhtablestagebridges);
            this.checkRowColumnNumber();
            this.lowestDWHFieldsInteger = -1;

        } else {
            // Give standard DWHFields.
            this.DWHTable = new DWHTable(-1, this.environment.EnvironmentId, 0, 'Dim', 'dim', 'Insert TableName here', '', '', '',);
            this.DWHFields.push(new DWHField(-1, '-', 'Primary_Field_Here', '', 'PK', 0, 0, 0, 0));
            this.DWHFields.push(new DWHField(-2, '-', 'Sys_FirstLoadTimeStamp', 'datetime', '', 0, 1, 0, 0));
            this.DWHFields.push(new DWHField(-3, '-', 'Sys_LastUpdateTimeStamp', 'datetime', '', 0, 2, 0, 0));
            this.DWHFields.push(new DWHField(-4, '-', 'Sys_Operation', 'varchar(1)', '', 0, 3, 0, 0));
            this.DWHFields.push(new DWHField(-5, '-', 'Sys_StageDB', 'nvarchar(200)', '', 0, 4, 0, 0));
            this.DWHFields.push(new DWHField(-6, '-', 'Sys_ValidFrom', 'datetime', '', 0, 5, 0, 0));
            this.DWHFields.push(new DWHField(-7, '-', 'Sys_ValidTo', 'datetime', '', 0, 6, 0, 0));
            this.DWHFields.push(new DWHField(-8, '-', 'Sys_CurrentRow', 'bit', '', 0, 7, 0, 0));

            // Set the variables in the correct position
            this.lowestDWHFieldsInteger = -9;
            this.highestRowNumber = 7;
        }

        // Set remaining variable in the correct position, sort all fields obtained.
        this.lowestDWHStageBridgeInteger = -1;
        this.sortAll();
    },

    methods: {

//               _____  _____   ____  _____  _____   ______          ___   _  _____
//              |  __ \|  __ \ / __ \|  __ \|  __ \ / __ \ \        / / \ | |/ ____|
//              | |  | | |__) | |  | | |__) | |  | | |  | \ \  /\  / /|  \| | (___
//              | |  | |  _  /| |  | |  ___/| |  | | |  | |\ \/  \/ / | . ` |\___ \
//              | |__| | | \ \| |__| | |    | |__| | |__| | \  /\  /  | |\  |____) |
//              |_____/|_|  \_\\____/|_|    |_____/ \____/   \/  \/   |_| \_|_____/

        trimErrorMessage(){
            var resultMessage = this.errorMessage;
            var messageLength = 0;
            var counter = 0
            for(var i = 0; i < this.errorMessage.length; i++){
                if(this.errorMessage[i] === '\n'){
                    counter = 0;
                }
                else if(counter > 100 && this.errorMessage[i] === ' ') {
                    counter = 0;
                    resultMessage = this.addStr(resultMessage, messageLength, '\n');
                    messageLength++;
                }
                else {
                    counter++;
                }
                messageLength++;
            }
            this.errorMessage = resultMessage;
        },

        addStr(str, index, stringToAdd){
            var a = str.substring(0, index) + stringToAdd + str.substring(index, str.length);
            return a;
        },

        /**
         * Changes the DWHTable TableType.
         * @param event
         */
        changeTableType(event) {
            this.DWHTable.TableType = event.target.value;
        },

        /**
         * Changes the LoadType of a TableStageBridge.
         * @param event
         */
        changeLoadType(event){
            this.getTableStageBridge(parseInt(event.target.value.split(',')[1])).LoadType = event.target.value.split(',')[0];
        },

        /**
         * Changes the Key field.
         * If a DWHField becomes a PK, also changes the FieldMapping and makes it non-writeable.
         * @param event
         * @param DWHFieldId
         */
        changeKey(event, DWHFieldId){
            if(event.target.value !== 'None') {
                this.getDWHField(DWHFieldId).SCD2 = 0;
                if(event.target.value === 'PK') this.clearFieldMappingsOnDWHFieldId(DWHFieldId);
            }
        },

        /**
         * CHecks if a DWHField has a Key value.
         * @param DWHFieldKey
         * @returns {boolean}
         */
        hasKey(DWHFieldKey){
            return DWHFieldKey !== '' && DWHFieldKey !== null;
        },

//                _____ ______ _______ _______ ______ _____   _____
//               / ____|  ____|__   __|__   __|  ____|  __ \ / ____|
//              | |  __| |__     | |     | |  | |__  | |__) | (___
//              | | |_ |  __|    | |     | |  |  __| |  _  / \___ \
//              | |__| | |____   | |     | |  | |____| | \ \ ____) |
//               \_____|______|  |_|     |_|  |______|_|  \_\_____/


        /**
         * Gets the DWHField based on the DWHFieldId
         * @param DWHFieldId
         * @returns {*}
         */
        getDWHField(DWHFieldId){
            var res = this.DWHFields.filter(obj => {
                return obj.DWHFieldId == DWHFieldId;
            })[0];
            return res;
        },

        /**
         * Gets the DWHField on RowNumber.
         * @param RowNumber
         * @returns {*}
         */
        getDWHFieldOnRowNumber(RowNumber){
            return this.DWHFields.filter(obj => {
                return obj.RowNumber === RowNumber;
            })[0];
        },

        /**
         * Gets the DWHTableStageBridge based on ColumnNumber.
         * @param ColumnNumber
         * @returns {*}
         */
        getDWHTableStageBridgeOnColumnNumber(ColumnNumber){
            return this.DWHTableStageBridges.filter(obj => {
                return obj.ColumnNumber === ColumnNumber;
            })[0];
        },

        /**
         * Gets the stage based on the StageDefinitionId
         * @param StageDefinitionId
         * @returns {StageDefinition|*}
         */
        getStage(StageDefinitionId) {
            var StageDef = this.Stages.filter(obj => {
                return obj.StageDefinitionId === StageDefinitionId;
            })[0];
            if(typeof StageDef == 'undefined') return new StageDefinition(-6,-6,-6,'Stage Undefined','Undefined');
            else return StageDef;
        },

        /**
         * This function returns the DWHFieldMapping belonging to the combination of DWHTableStageBridgeId and
         * DWHFieldId.
         *
         * If either the DWHFIeldId or DWHTableStageBridgeId does not exist, a random fieldmapping is returned,
         * but does not actually exist within the DWHFieldMapping variable. This is because a FieldMapping needs
         * a valid DWHFieldId and DWHTSBId.
         *
         * If no DWHFieldMapping can be found on the combination of two valid IDs, a new one is created and pushed
         * onto the DWHFieldMapping variable.
         */
        getFieldMapping(DWHTableStageBridgeId, DWHFieldId) {
            // First try and get the correct FieldMapping
            var res = this.DWHFieldMappings.filter(obj => {
                return obj.DWHFieldId === DWHFieldId && obj.DWHTableStageBridgeId === DWHTableStageBridgeId;
            })[0];
            // If either the DWHFieldId or the DWHTableStageBridgeId are incorrect, return a DWHFieldMapping not in DWHFieldMappings.
            // This means this DWHFieldMapping will not be saved.
            if( typeof DWHFieldId === 'undefined' ||  typeof DWHTableStageBridgeId === 'undefined' || !DWHFieldId || !DWHTableStageBridgeId) {
                return new DWHFieldMapping(-1, -999999, -999999, 0, 'Contact administrator.');
            }
            // If there is no result, add a new DWHFieldMapping to DWHFieldMappings and return it.
            else if(!res){
                var DWHFM = new DWHFieldMapping(
                    -1,
                    DWHFieldId,
                    DWHTableStageBridgeId,
                    0,
                    '',
                );
                this.DWHFieldMappings.push(DWHFM);
                return DWHFM;
            }
            // If there is a result, return it.
            else {
                return res;
            }
        },

        /**
         * Gets the TableStageBridge on the TableStageBridgeId
         * @param TableStageBridgeId
         * @returns {*}
         */
        getTableStageBridge(TableStageBridgeId){
            return this.DWHTableStageBridges.filter(obj => {
                return obj.DWHTableStageBridgeId === TableStageBridgeId;
            })[0];
        },

        /**
         * Gets the Stored Procedure that belongs to the given DWHTableStageBridgeId
         * @param DWHTableStageBridgeId the ID of the DWHTableStageBridge
         */
        getStoredProcedure(DWHTableStageBridgeId){
            return this.StoredProcedures.filter(obj => {
                return obj.DWHTableStageBridgeId === DWHTableStageBridgeId;
            })[0];
        },

        /**
         * Adds a StoredProcedure to the StoredProcedure list.
         * Also adjusts the stored procedure to add enters to it.
         * @param DWHTableStageBridgeId
         * @param StoredProcedure
         */
        addStoredProcedure(DWHTableStageBridgeId, StoredProcedure){
            this.StoredProcedures.push(
                new StoredProcedureModel(DWHTableStageBridgeId, StoredProcedure)
            );
        },

        /**
         * Modifies the StoredProcedure belonging to a DWHTableStageBridge
         * @param DWHTableStageBridgeId
         * @param StoredProcedure
         */
        modifyStoredProcedure(DWHTableStageBridgeId, StoredProcedure){
            this.getStoredProcedure(DWHTableStageBridgeId).StoredProcedure = StoredProcedure;
        },

        /**
         * Removes the StoredProcedure entry for a specified DWHTableStageBridgeId
         * @param DWHTableStageBridgeId
         */
        removeStoredProcedure(DWHTableStageBridgeId){
            this.StoredProcedures.splice(this.StoredProcedures.indexOf(this.getStoredProcedure(DWHTableStageBridgeId)), 1);
        },

//               _____   ______          _______ _   _  _____ ____  _     _    _ __  __ _   _  _____
//              |  __ \ / __ \ \        / / ____| \ | |/ ____/ __ \| |   | |  | |  \/  | \ | |/ ____|
//              | |__) | |  | \ \  /\  / / (___ |  \| | |   | |  | | |   | |  | | \  / |  \| | (___
//              |  _  /| |  | |\ \/  \/ / \___ \| . ` | |   | |  | | |   | |  | | |\/| | . ` |\___ \
//              | | \ \| |__| | \  /\  /  ____) | |\  | |___| |__| | |___| |__| | |  | | |\  |____) |
//              |_|  \_\\____/   \/  \/  |_____/|_| \_|\_____\____/|______\____/|_|  |_|_| \_|_____/

        /**
         * Adds a new DWHField. Also adds DWHFieldMappings for every DWHTSB.
         */
        addRow() {
            /**
             * First create a DWHField.
             */
            this.DWHFields.push(new DWHField(
                this.lowestDWHFieldsInteger,
                this.DWHTable.DWHTableId,
                '',
                'nvarchar(200)',
                '',
                0,
                this.highestRowNumber+1,
                0,
                0,
            ));

            /**
             * Then loop through all DWHTSBs, create a FieldMapping for every combination of
             * DWHField and DWHTSB within this DWHTable.
             */
            this.DWHTableStageBridges.forEach(DWHTableStageBridge =>
                this.DWHFieldMappings.push(new DWHFieldMapping(
                        '-1',
                        this.lowestDWHFieldsInteger,
                        DWHTableStageBridge.DWHTableStageBridgeId,
                        0,
                        '',
                    )
                ));

            /**
             * Now find out what the first occurence is of a SYS_ field.
             * Insert the new DWHField above that.
             * Use a flag to make sure the first occurence of a SYS_ field always becomes the initial value
             * of SysRowNumber.
             */
            var SysRowNumber = 0;
            var flag=true;
            this.DWHFields.forEach(DWHField => {
                if(DWHField.FieldName.startsWith("Sys_")){
                    // Use the flag to make the first occurence of a SYS field the value.
                    if(flag){
                        SysRowNumber = DWHField.RowNumber;
                        flag=false;
                    }
                    // Only replace the SysRowNumber if the found rowNumber is smaller.
                    if(SysRowNumber > DWHField.RowNumber) SysRowNumber = DWHField.RowNumber;
                }
            })

            /**
             * Create an event with two variables. This allows us to use the moveRow function to move
             * the newly created DWHField into the correct position.
             * After this is done, sort all the fields.
             */
            var event = {};
            event.oldIndex = this.highestRowNumber+1;
            event.newIndex = SysRowNumber;

            this.moveRow(event);
            this.sortAll();

            /**
             * Lastly, edit two variables to make sure other DWHFields can also be added after this.
             *
             * The lowestDWHFieldsInteger keeps track of the lowest (negative) DWHFieldsId. Any newly created
             * DWHField gets a negative Id, as it does not have an entry in the database yet. In decrementing this field,
             * we make sure that all newly added DWHFields still have a unique identifier, needed for the connection
             * with DWHFieldMapping.
             *
             * The highestRowNumber simply keeps track of the number of rows.
             */
            this.lowestDWHFieldsInteger--;
            this.highestRowNumber++;
        },

        /**
         * Removes a DWHField from the DWHTable.
         * @param DWHFieldId
         */
        removeRow(DWHFieldId){
            /**
             * First it gets the RowNumber.
             * Then it removes the DWHField from the list of DWHFields
             * Then it removes all DWHFieldMappings that belong to the DWHField.
             * It adds the DWHFieldId to the list of removed DWHFields.
             * Afterwards, it moves every DWHField below it up a single row.
             * Finally, it decreases the highestRowNumber by one and resorts all fields.
             */
            let currentRow = this.getDWHField(DWHFieldId).RowNumber;
            this.DWHFields.splice(this.DWHFields.indexOf(this.getDWHField(DWHFieldId)), 1);
            this.DWHFieldMappings = this.DWHFieldMappings.filter(FM => FM.DWHFieldId !== DWHFieldId);
            if(DWHFieldId >= 0) this.removedDWHFields.push(DWHFieldId);
            for (let i = currentRow+1; i <= this.highestRowNumber; i++) {
                this.getDWHFieldOnRowNumber(i).RowNumber = i-1;
            }
            this.highestRowNumber--;
            this.sortAll();
        },

        /**
         * Gets called when a selected row gets moved (dropped) on a place.
         * The function then recalculates the RowNumber of all DWHFields.
         * @param evt - the event
         */
        moveRow(evt){
            var index = evt.oldIndex;
            var futureIndex = evt.newIndex;
            var up = futureIndex < index;
            var movedDWHField = this.getDWHFieldOnRowNumber(index);
            if(up){
                for (let i = index-1; i >= futureIndex; i--) {
                    this.getDWHFieldOnRowNumber(i).RowNumber = i+1;
                }
                movedDWHField.RowNumber = futureIndex;
            }
            else {
                for (let i = index+1; i <= futureIndex; i++) {
                    this.getDWHFieldOnRowNumber(i).RowNumber = i-1;
                }
                movedDWHField.RowNumber = futureIndex;
            }
        },

        /**
         * Adds a DWHTableStageBridge
         * @param event
         */
        addDWHTableStageBridge(event){
            if(event.target.value > 0){

                /**
                 * Make sure the DWHTSB has a StoredProcedureModel.
                 */
                this.addStoredProcedure(this.lowestDWHStageBridgeInteger, '');

                /**
                 * Then get the stage, and create a new DWHTSB with correct info.
                 */
                var stage = this.getStage(parseInt(event.target.value));
                this.DWHTableStageBridges.push(new DWHTableStageBridge(
                    this.lowestDWHStageBridgeInteger,
                    this.DWHTable.DWHTableId,
                    stage.StageDefinitionId,
                    stage.StageName,
                    0,
                    'Merge',
                    '',
                    '',
                    '',
                    parseInt(this.highestColumnNumber+1),
                ));

                /**
                 * Create DWHFieldMappings for every new DWHField DWHTSB combi.
                 * Some FieldMappings get an automatic value.
                 */
                // Nu extra DWHFieldMappings aanmaken
                this.DWHFields.forEach(DWHField =>
                    {
                        var MappingQuery = '';
                        if(DWHField.FieldName === 'Sys_FirstLoadTimeStamp'){
                            MappingQuery = 'getDate()';
                        }
                        else if(DWHField.FieldName === 'Sys_LastUpdateTimeStamp'){
                            MappingQuery = 'getDate()';
                        }
                        else if(DWHField.FieldName === 'Sys_Operation'){
                            MappingQuery = '\'I\'';
                        }
                        else if(DWHField.FieldName === 'Sys_StageDB'){
                            MappingQuery = '\'' + stage.StageName + '\'';
                        }
                        else if(DWHField.FieldName === 'Sys_ValidFrom'){
                            MappingQuery = '\'1900 - 01 - 01\'';
                        }
                        else if(DWHField.FieldName === 'Sys_ValidTo'){
                            MappingQuery = '\'2099 - 12 - 31\'';
                        }
                        else if(DWHField.FieldName === 'Sys_CurrentRow'){
                            MappingQuery = '1';
                        }
                        else if(DWHField.Key ==='PK'){
                            MappingQuery = '\'\'';
                        }
                        this.DWHFieldMappings.push(new DWHFieldMapping(
                                '-1',
                                DWHField.DWHFieldId,
                                this.lowestDWHStageBridgeInteger,
                                0,
                                MappingQuery,
                            )
                        )
                    }
                );
                /**
                 * Edit the variables needed to make this work multiple times. Same stuff as with add DWHField.
                 */
                this.lowestDWHStageBridgeInteger--;
                this.highestColumnNumber++;
                document.getElementById("stages").selectedIndex = -1;
            }
        },

        /**
         * Remove a DWHTSB
         * @param DWHTableStageBridgeId
         */
        removeTableStageBridge(DWHTableStageBridgeId){

            // Get the ColumnNumber
            let currentColumn = this.getTableStageBridge(DWHTableStageBridgeId).ColumnNumber;

            // Remove the TableStageBridge from TableStageBridges
            this.DWHTableStageBridges.splice(this.DWHTableStageBridges.indexOf(this.getTableStageBridge(DWHTableStageBridgeId)), 1);

            // Remove the TableStageBridge from StoredProcedures
            this.removeStoredProcedure(DWHTableStageBridgeId);

            // Remove all DWHFieldMappings where DWHTableStageBridgeId === DWHTableStageBridgeId
            this.DWHFieldMappings = this.DWHFieldMappings.filter(FM => FM.DWHTableStageBridgeId !== DWHTableStageBridgeId);

            // Add the DWHTableStageBridgeId to the list of removed field ID's if positive (and thus already known in the DB)
            if(DWHTableStageBridgeId >= 0) this.removedDWHTableStageBridges.push(DWHTableStageBridgeId);

            // Let all columns below move up a column
            for (let i = currentColumn+1; i <= this.highestColumnNumber; i++) {
                this.getDWHTableStageBridgeOnColumnNumber(i).ColumnNumber = i-1;
            }

            // Set the highestRowNumber down by one
            this.highestColumnNumber--;

            // Re-sort all DWHTableStageBridges
            this.sortAll();

        },

        /**
         * Moves a DWHTSB one column to the left
         * @param DWHTableStageBridgeId
         */
        columnLeft(DWHTableStageBridgeId){
            // Get the ColumnNumber
            let currentColumn =  this.getTableStageBridge(DWHTableStageBridgeId).ColumnNumber;

            // Do not continue if the column is already at the top.
            if(currentColumn <= 0) return;

            // Get the DWHTableStageBridge above and edit its ColumnNumber
            this.getDWHTableStageBridgeOnColumnNumber(currentColumn-1).ColumnNumber = currentColumn;

            // Set the ColumnNumber of the field to the correct ColumnNumber.
            this.getTableStageBridge(DWHTableStageBridgeId).ColumnNumber = currentColumn-1;

            // Re-sort all DWHTableStageBridges
            this.sortAll();
        },

        /**
         * Moves a DWHTSB one column to the right
         * @param DWHTableStageBridgeId
         */
        columnRight(DWHTableStageBridgeId){
            // Get the ColumnNumber
            let currentColumn =  this.getTableStageBridge(DWHTableStageBridgeId).ColumnNumber;

            // Do not continue if the row is already at the bottom.
            if(currentColumn >= this.highestColumnNumber) return;

            // Get the DWHField below and edit its RowNumber
            this.getDWHTableStageBridgeOnColumnNumber(currentColumn+1).ColumnNumber = currentColumn;

            // Set the RowNumber of the field to the correct RowNumber.
            this.getTableStageBridge(DWHTableStageBridgeId).ColumnNumber = currentColumn+1;

            // Re-sort all DWHTableStageBridges
            this.sortAll();
        },


//               ____  _    _ _______ _______ ____  _   _  _____                     _____ _____
//              |  _ \| |  | |__   __|__   __/ __ \| \ | |/ ____|   ___        /\   |  __ \_   _|
//              | |_) | |  | |  | |     | | | |  | |  \| | (___    ( _ )      /  \  | |__) || |
//              |  _ <| |  | |  | |     | | | |  | | . ` |\___ \   / _ \/\   / /\ \ |  ___/ | |
//              | |_) | |__| |  | |     | | | |__| | |\  |____) | | (_>  <  / ____ \| |    _| |_
//              |____/ \____/   |_|     |_|  \____/|_| \_|_____/   \___/\/ /_/    \_\_|   |_____|
//

        saveGenerate(){

            if(!this.editMode) {
                // This check is also in the HTML part of this file, and thus is checked (prevented) in two places.
                this.notifyUser("Table must first be created before procedures can be generated. Use the other button.");
                return false;
            }

            this.disableButtons('Please hold while scripts are running...');

            if(!this.checkDatabaseAndInput()) {
                this.enableButtons();
                return;
            }

            /**
             * Form a package with all the items the server needs to process the request properly.
             * Send this package to the server
             */
            DWHTableService.saveToAutomatorGenerateLoadProcedures(this.createSavingPackage()).then(response => {
                this.logger(response);
                if(!this.handleResult('Saving', response)){
                    this.enableButtons();
                    return;
                }

                /**
                 * Check if the result is valid. If it is, load all values into memory
                 * to make sure all ID's are correct.
                 */
                this.createDWHTable(response.data.Data.DWHTable);
                this.createDWHFields(response.data.Data.DWHFields);
                this.createDWHTableStageBridges(response.data.Data.DWHTSBs);
                this.createDWHFieldMappings(response.data.Data.DWHFMs);
                this.appendStoredProcedures(response.data.Data.LoadProcedures);

                this.sortAll();

                // Make sure to update some page-variables to the newest setting.
                // Set the OldTableSchemaTableName variable for the header of the page.
                this.StaticTableSchemaTableName = this.DWHTable.TableSchema + '.' + this.DWHTable.TableName;
                this.removedDWHFields = [];
                this.removedDWHTableStageBridges = [];
                this.enableButtons();
                this.postMessageForSeconds('Scripts are finished!', 3000);
            });
        },

        saveDropGenerate(){
            this.disableButtons('Please hold while scripts are running...');

            if(!this.checkDatabaseAndInput()) {
                this.enableButtons();
                return;
            }

            /**
             * Form a package with all the items the server needs to process the request properly.
             * Send this package to the server
             */
            DWHTableService.saveToAutomatorDropTableGenerateLoadProcedures(this.createSavingPackage()).then(response => {
                this.logger(response);
                if(!this.handleResult('Saving', response)){
                    this.enableButtons();
                    return;
                }

                // If the table was newly created, redirect to the edit page instead of the create page.
                // Reason for this is to save me a headache.
                if (!this.editMode) {
                    window.location.replace("/user/dwhtables/" + response.data.Data.DWHTable.EnvironmentId + '/'
                        + response.data.Data.DWHTable.DWHTableId + '/edit');
                }

                /**
                 * Check if the result is valid. If it is, load all values into memory
                 * to make sure all ID's are correct.
                 */
                this.createDWHTable(response.data.Data.DWHTable);
                this.createDWHFields(response.data.Data.DWHFields);
                this.createDWHTableStageBridges(response.data.Data.DWHTSBs);
                this.createDWHFieldMappings(response.data.Data.DWHFMs);
                this.appendStoredProcedures(response.data.Data.LoadProcedures);

                this.postMessageForSeconds('Scripts are finished!', 3000);
                this.sortAll();

                if (!this.editMode) {
                    this.editMode = true;
                }

                // Make sure to update some page-variables to the newest setting.
                // Set the OldTableSchemaTableName variable for the header of the page.
                this.StaticTableSchemaTableName = this.DWHTable.TableSchema + '.' + this.DWHTable.TableName;
                this.removedDWHFields = [];
                this.removedDWHTableStageBridges = [];
                this.enableButtons();
                this.postMessageForSeconds('Scripts are finished!', 3000);
            });
        },

        disableButtons(message){
            this.sendingData = true;
            this.updateMessage = message;
            this.errorMessage = '';
        },

        enableButtons(){
            this.sendingData = false;
            this.updateMessage = '';
        },



//               _    _ ______ _      _____  ______ _____    ______ _    _ _   _  _____ _______ _____ ____  _   _  _____
//              | |  | |  ____| |    |  __ \|  ____|  __ \  |  ____| |  | | \ | |/ ____|__   __|_   _/ __ \| \ | |/ ____|
//              | |__| | |__  | |    | |__) | |__  | |__) | | |__  | |  | |  \| | |       | |    | || |  | |  \| | (___
//              |  __  |  __| | |    |  ___/|  __| |  _  /  |  __| | |  | | . ` | |       | |    | || |  | | . ` |\___ \
//              | |  | | |____| |____| |    | |____| | \ \  | |    | |__| | |\  | |____   | |   _| || |__| | |\  |____) |
//              |_|  |_|______|______|_|    |______|_|  \_\ |_|     \____/|_| \_|\_____|  |_|  |_____\____/|_| \_|_____/

        createCommPackage(Dataa){
            let Data = {}
            return new CommunicationPackage(
                1,
                '',
                Data = Dataa
            );
        },

        /**
         * Clears the FieldMapping query for all fieldmappings that belong to a certain DWHField.
         * @param DWHFieldId
         */
        clearFieldMappingsOnDWHFieldId(DWHFieldId){
            this.DWHFieldMappings.forEach(FM => {
                if(FM.DWHFieldId === DWHFieldId) FM.MappingQuery = '\'\'';
            })
        },

        /**
         * This function takes a message to display to the user for a set amount of time.
         * @param message
         * @param ms
         * @returns {Promise<void>}
         */
        async postMessageForSeconds(message, ms){
            this.updateMessage = message;

            await new Promise(r => setTimeout(r, ms));

            this.updateMessage = '';
        },

        /**
         *  Returns true if the result was valid, false if not.
         */
        handleResult(actionName, response){
            this.logger("The request for " + actionName + " was (1 = Success, 0 = Failed): " + response.data.Success);
            if(!response.data.Success) {
                this.notifyUser("Something went wrong: " + response.data.Message);
                this.logger("ERROR: " + response.data.Message);
            }
            return response.data.Success;
        },

        /**
         *  Special case of the handleResult function. It saves the response message to the bottom of the page
         *  so any information in it can be viewed at ease.
         */
        handleResultDWH(actionName, response){
            this.logger("The request for " + actionName + " was (1 = Success, 0 = Failed): " + response.data.Success);
            if(!response.data.Success) {
                this.errorMessage = response.data.Message;
                this.trimErrorMessage()
                this.postMessageForSeconds('Something went wrong...!', 3000);
                this.notifyUser("Something went wrong. Please see the bottom of this page for the error. The error is also shown in the console.");
                this.logger("ERROR: " + response.data.Message);
            }
            return response.data.Success;
        },

        /**
         * Special case of the handleResult function.
         * @param actionName
         * @param response
         * @returns {string}
         */
        handleResultDWHTSB(actionName, response){
            this.logger("The request for " + actionName + " was (1 = Success, 0 = Failed): " + response.data.Success);
            if(!response.data.Success) {
                this.notifyUser(actionName + " went wrong. Please see the bottom of this page for the error. The error is also shown in the console.");
                this.logger("ERROR: " + response.data.Message);
            }
            return response.data.Success;
        },

        // Allows the program to easily know if it's in editing mode or not.
        isEdit(){
            // this.checkRowColumnNumber();
            return this.editMode > 0;
        },

        /**
         * This function checks all the input from the fields on the following:
         *          - The DWHTableName cannot be the same as another DWHTableName in the environment
         *          - All datatypes contain a value
         *          - All DWHFields have a unique fieldname
         *          - At least one DWHField is a BK
         *          - All DWHTSBs have unique names
         *          - All FieldMappings are not null, except for PK fields.
         */
        checkInputs(){
            var result = {valid: true, message: ''};

            result = this.namesNotNull();
            if(!result.valid) return result;

            result = this.uniqueDWHTableNames();
            if(!result.valid) return result;

            result = this.validFields();
            if(!result.valid) return result;

            result = this.validViewFieldCheck();
            if(!result.valid) return result;

            result = this.validBKCheck();
            if(!result.valid) return result;

            result = this.uniqueDWHTSBNames();
            if(!result.valid) return result;

            result = this.validFieldMappings();
            if(!result.valid) return result;

            // Return the result of the check to the caller.
            return result;
        },

        /**
         * Checks if the names of TableSchema, TableType, ViewSchema, ViewType are not null
         * @returns {{valid: boolean, message: string}}
         */
        namesNotNull(){
            var result = {valid: true, message: ''};

            if(this.DWHTable.TableSchema == ''){
                result.valid = false;
                result.message = 'TableSchema cannot be empty.';
            }

            if(this.DWHTable.TableName == ''){
                result.valid = false;
                result.message = 'TableName cannot be empty.';
            }

            if(this.DWHTable.ViewSchema == ''){
                result.valid = false;
                result.message = 'ViewSchema cannot be empty.';
            }

            if(this.DWHTable.ViewName == ''){
                result.valid = false;
                result.message = 'ViewName cannot be empty.';
            }

            return result;
        },

        /**
         * This part makes sure the DWHTableName is unique
         * TODO: Expand this
         */
        uniqueDWHTableNames(){
            var result = {valid: true, message: ''};

            var currentTableName = this.DWHTable.TableSchema + '.' + this.DWHTable.TableName;
            var currentViewName = this.DWHTable.ViewSchema + '.' + this.DWHTable.ViewName;
            currentTableName = currentTableName.toLowerCase();
            currentViewName = currentViewName.toLowerCase();

            var tableName2 = '';
            var viewName2 = '';

            this.DWHTables.forEach(DWHTable2 => {

                // Do not check if the DWHTable2 is the same DWHTable as the current DWHTable.
                if(this.DWHTable.DWHTableId === DWHTable2.DWHTableId) return;

                // Set the variables to be checked.
                tableName2 = DWHTable2.TableSchema + '.' + DWHTable2.TableName;
                viewName2 = DWHTable2.ViewSchema + '.' + DWHTable2.ViewName;
                tableName2 = tableName2.toLowerCase();
                viewName2 = viewName2.toLowerCase();

                if(currentTableName === tableName2){
                    result.valid = false;
                    result.message = "DWHTableName (TableSchema + TableName) is equal to another DWHTableName within this" +
                        " environment. Make sure the DWHTableName is unique.";
                }

                if(currentTableName === viewName2){
                    result.valid = false;
                    result.message = "DWHTableName (TableSchema + TableName) is equal to a ViewName (ViewSchema + ViewName)" +
                        " within this environment. Make sure the DWHTableName is unique.";
                }

                if(currentViewName === viewName2){
                    result.valid = false;
                    result.message = "ViewName (ViewSchema + ViewName) is equal to another ViewName within this" +
                        " environment. Make sure the ViewName is unique.";
                }

                if(currentViewName === tableName2){
                    result.valid = false;
                    result.message = "ViewName (ViewSchema + ViewName) is equal to a DWHTableName (TableSchema + TableName) " +
                        "within this environment. Make sure the ViewName is unique.";
                }
            });
            return result;
        },

        validFields(){
            var result = {valid: true, message: ''};

            this.DWHFields.forEach(DWHField => {

                // Check if all DWHFields have a datatype
                if(DWHField.DataType === '') {
                    result.valid = false;
                    result.message = "Please make sure all DWHFields have a valid datatype.";
                }

                // Check if no fieldname appears twice
                this.DWHFields.forEach(DWHField2 => {
                    if(DWHField.DWHFieldId !== DWHField2.DWHFieldId && DWHField.FieldName === DWHField2.FieldName){
                        result.valid = false;
                        result.message = "Please make sure all DWHFields have a unique fieldname.";
                    }
                });
            });

            return result;
        },

        validViewFieldCheck(){
            var result = {valid: false, message: "No field is selected for the view."};

            // If any viewfield is found, set flag to valid
            this.DWHFields.forEach(DWHField => {
                if(DWHField.FieldIncludedInView > 0){
                    result.valid = true;
                    result.message = '';
                }
            });
            return result;
        },

        validBKCheck(){
            var result = {valid: false, message: "No field is registered as a BK (business key)."};

            // If any BK is found, set flag to valid
            this.DWHFields.forEach(DWHField => {
                if(DWHField.Key === 'BK'){
                    result.valid = true;
                    result.message = '';
                }
            });
            return result;
        },

        /**
         * Make sure all DWHTableStageBridges have unique names.
         */
        uniqueDWHTSBNames(){
            var result = {valid: true, message: ''};
            this.DWHTableStageBridges.forEach(DWHTSB => {
                this.DWHTableStageBridges.forEach(DWHTSB2 => {
                    if(DWHTSB.DWHTableStageBridgeId !== DWHTSB2.DWHTableStageBridgeId && DWHTSB.DWHTableStageBridgeName === DWHTSB2.DWHTableStageBridgeName){
                        result.valid = false;
                        result.message = "Please make sure all Stored Procedures have a unique name. " +
                            "\nThis error is caused by the following names: \n"
                            + DWHTSB.DWHTableStageBridgeName + "\n" + DWHTSB2.DWHTableStageBridgeName;
                    }
                })
            });
            return result;
        },

        /**
         * Check if all DWHFieldMappings are not null, except for PK fields.
         */
        validFieldMappings(){
            var result = {valid: true, message: ''};
            this.DWHFieldMappings.forEach(DWHFM => {
                if(DWHFM.MappingQuery === '' && this.getDWHField(DWHFM.DWHFieldId).Key !== 'PK'){
                    result.valid = false;
                    result.message = "Please make sure all DWHFieldMappings are not null.";
                }
            })
            return result;
        },

        sortAll(){
            this.DWHFields.sort(function(a, b) {
                return Math.abs(a.RowNumber) - Math.abs(b.RowNumber);
            });
            this.DWHTableStageBridges.sort(function(a, b) {
                return Math.abs(a.ColumnNumber) - Math.abs(b.ColumnNumber);
            });
        },

        checkRowColumnNumber(){
            this.sortAll();
            /**
             * The first part is to check if there are no null values in the Row/ColumnNumber. If there are,
             * simply rewrite all of the values.
             */

            let RowNumberNull = false;
            this.DWHFields.forEach(FM => {
                if(!FM.RowNumber && FM.RowNumber !== 0){
                    RowNumberNull = true;
                }
            })
            if(RowNumberNull){
                let i = 0;
                this.DWHFields.forEach(FM => {
                    FM.RowNumber = i;
                    i++;
                })
            }

            let ColumnNumberNull = false;
            this.DWHTableStageBridges.forEach(DWHTSB => {
                if(!DWHTSB.ColumnNumber && DWHTSB.ColumnNumber !== 0){
                    ColumnNumberNull = true;
                }
            })
            if(ColumnNumberNull){
                let i = 0;
                this.DWHTableStageBridges.forEach(DWHTSB => {
                    DWHTSB.ColumnNumber = i;
                    i++;
                })
            }

            /**
             * This next part only needs to happen to the Row and ColumnNumbers respectively if they haven't been
             * through the previous loop.
             * All values were already sorted at the start of this function.
             */
            if(!RowNumberNull){
                let first = true;
                let i = 0;
                let reset = false;
                let hasZero = false;
                this.DWHFields.forEach(FM => {
                    if(first){
                        i = FM.RowNumber;
                        first = false;
                    }
                    else
                    if(i !== FM.RowNumber-1) reset = true;
                    if(FM.RowNumber === 0)hasZero = true;

                })

                if(reset || !hasZero){
                    let i = 0;
                    this.DWHFields.forEach(FM => {
                        FM.RowNumber = i;
                        i++;
                    })
                }
            }
            if(!ColumnNumberNull){
                let first = true;
                let i = 0;
                let reset = false;
                let hasZero = false;
                this.DWHTableStageBridges.forEach(DWHTSB => {
                    if(first){
                        i = DWHTSB.ColumnNumber;
                        first = false;
                    } else {
                        if(i !== DWHTSB.ColumnNumber-1){
                            reset = true;
                        }
                    }
                    if(DWHTSB.ColumnNumber === 0) hasZero = true;
                })

                if(reset || !hasZero){
                    let i = 0;
                    this.DWHTableStageBridges.forEach(DWHTSB => {
                        DWHTSB.ColumnNumber = i;
                        i++;
                    })
                }
            }
        },

        // A simple helper function that creates the DWHTable
        createDWHTable(original){
            this.logger("Creating DWHTable inner");
            this.DWHTable = new DWHTable(
                parseInt(original.DWHTableId),
                parseInt(original.EnvironmentId),
                parseInt(original.Revision),
                original.TableType,
                original.TableSchema,
                original.TableName,
                original.ViewSchema,
                original.ViewName,
                original.ViewWhere,
            );
        },

        // A simple helper function that creates all the DWHFields, as well as keeps track of what the highest
        // row number is, and sorts them at the end.
        createDWHFields(original) {
            this.logger("Creating DWHFields inner");
            this.highestRowNumber = 0;

            this.DWHFields = original.map(item => {
                if (parseInt(item.RowNumber) > this.highestRowNumber) {
                    this.highestRowNumber = parseInt(item.RowNumber);
                }
                return new DWHField(
                    parseInt(item.DWHFieldId),
                    parseInt(item.DWHTableId),
                    item.FieldName,
                    item.DataType,
                    item.Key,
                    parseInt(item.SCD2),
                    parseInt(item.RowNumber),
                    item.Revision, // TODO: When revision gets implemented, this should be changed to a parseInt()
                    parseInt(item.FieldIncludedInView),
                );
            });
        },

        // A simple helper function that creates the DWHFieldMappings
        createDWHFieldMappings(original){
            this.logger("Creating FieldMapping inner");
            this.DWHFieldMappings = original.map(item => {
                // Set the DWHFieldMapping to two apostrophies as to not
                // mess with he system if it's a legacy fieldmapping
                if(item.MappingQuery === '' && this.getDWHField(item.DWHFieldId).Key === 'PK'){
                    item.MappingQuery = '\'\'';
                }

                return new DWHFieldMapping(
                    parseInt(item.DWHFieldMappingId),
                    parseInt(item.DWHFieldId),
                    parseInt(item.DWHTableStageBridgeId),
                    parseInt(item.Revision),
                    item.MappingQuery,
                );
            });
        },

        // A simple helper function that creates the DWHTableStageBridges
        createDWHTableStageBridges(original){
            this.StoredProcedures = [];
            this.highestColumnNumber = -1;

            this.DWHTableStageBridges = original.map(item => {
                if(parseInt(item.ColumnNumber) > this.highestColumnNumber) {
                    this.highestColumnNumber = parseInt(item.ColumnNumber);
                }
                this.addStoredProcedure(item.DWHTableStageBridgeId, '');
                return new DWHTableStageBridge(
                    parseInt(item.DWHTableStageBridgeId),
                    parseInt(item.DWHTableId),
                    parseInt(item.StageDefinitionId),
                    item.DWHTableStageBridgeName,
                    parseInt(item.Revision),
                    item.LoadType,
                    item.PreQuery,
                    item.FromQuery,
                    item.PostQuery,
                    parseInt(item.ColumnNumber),
                );
            });
        },

        /**
         * A simple helper function that appends the generated stored procedures to the correct DWHTableStageBridges.
         */
        appendStoredProcedures(response){
            var errorCounter = 0;
            var errorMessages = [];
            this.DWHTSBerrorIDs = [];

            response.forEach(element => {
                this.modifyStoredProcedure(element.Data['DWHTableStageBridgeId'], element.Data['LoadProcedure']);
                if(!element.Success){
                    this.DWHTSBerrorIDs.push(element.Data['DWHTableStageBridgeId']);
                    errorCounter++;
                    errorMessages.push(element.Message);
                }
            })

            if(errorCounter > 0){
                this.errorMessage = errorMessages.join('\n\n\n\n');
                this.trimErrorMessage()
                this.notifyUser(errorCounter + " DWHTSB's went wrong. All error messages should be visible at the bottom" +
                    "of this page.");
            }
        },

        checkDatabaseAndInput(){
            // Make sure the inputs are correct first, do not want to send incorrect data to the server.
            let result = this.checkInputs();
            if(!result.valid) {
                this.notifyUser(result.message);
                return false;
            }
            return true;
        },

        createSavingPackage(){
            return this.createCommPackage({
                DWHTable                        : this.DWHTable,
                DWHFields                       : this.DWHFields,
                DWHFieldMappings                : this.DWHFieldMappings,
                DWHTableStageBridges            : this.DWHTableStageBridges,
                RemovedDWHFields                : this.removedDWHFields,
                RemovedDWHTableStageBridges     : this.removedDWHTableStageBridges,
            });
        },

        /**
         * Checks if the combination of DWHTableSchema + DWHTableName is identical to any other combination
         * of DWHTableSchema + DWHTableName or ViewSchema + ViewName.
         * @returns {boolean}
         */
        isDWHTableNameUnique(){
            var result = true;

            var currentTableName = this.DWHTable.TableSchema + '.' + this.DWHTable.TableName;
            var currentViewName = this.DWHTable.ViewSchema + '.' + this.DWHTable.ViewName;
            currentTableName = currentTableName.toLowerCase();
            currentViewName = currentViewName.toLowerCase();

            if(currentTableName === currentViewName) result = false;

            var tableName2 = '';
            var viewName2 = '';

            this.DWHTables.forEach(DWHTable2 => {

                // Do not check if the DWHTable2 is the same DWHTable as the current DWHTable.
                if(this.DWHTable.DWHTableId === DWHTable2.DWHTableId) return;

                // Set the variables to be checked.
                tableName2 = DWHTable2.TableSchema + '.' + DWHTable2.TableName;
                viewName2 = DWHTable2.ViewSchema + '.' + DWHTable2.ViewName;
                tableName2 = tableName2.toLowerCase();
                viewName2 = viewName2.toLowerCase();

                if(currentTableName === tableName2) result = false;
                if(currentTableName === viewName2) result = false;
            });
            return result;
        },

        /**
         * Checks if the combination of ViewSchema + ViewName is identical to any other combination
         * of ViewSchema + ViewName or DWHTableSchema + DWHTableName.
         * @returns {boolean}
         */
        isViewNameUnique(){
            var result = true;

            var currentTableName = this.DWHTable.TableSchema + '.' + this.DWHTable.TableName;
            var currentViewName = this.DWHTable.ViewSchema + '.' + this.DWHTable.ViewName;
            currentTableName = currentTableName.toLowerCase();
            currentViewName = currentViewName.toLowerCase();

            if(currentTableName === currentViewName) result = false;

            var tableName2 = '';
            var viewName2 = '';

            this.DWHTables.forEach(DWHTable2 => {

                // Do not check if the DWHTable2 is the same DWHTable as the current DWHTable.
                if(this.DWHTable.DWHTableId === DWHTable2.DWHTableId) return;

                // Set the variables to be checked.
                tableName2 = DWHTable2.TableSchema + '.' + DWHTable2.TableName;
                viewName2 = DWHTable2.ViewSchema + '.' + DWHTable2.ViewName;
                tableName2 = tableName2.toLowerCase();
                viewName2 = viewName2.toLowerCase();

                if(currentViewName === tableName2) result = false;
                if(currentViewName === viewName2) result = false;
            });
            return result;
        },

        notifyUser(message){
            // let user know the message
            alert(message);
        },

        logger(message){
            if(this.debug){
                console.log(message);
            }
        }
    }
}
</script>

<style>
.padding-0{
    padding-right:1px;
    padding-left:1px;
}

.padding-left{
    padding-right:1px;
    padding-left:25px;
}

.text-red{
    color: #ff0000;
}

.unevenrows{
    /*background-color: #857b26;*/
    background-color: lightgoldenrodyellow;
}

.horizontalScrollBarDiv {
    transform: rotateX(180deg);
    overflow-x: auto;
}
.horizontalScrollBarPre {
    transform: rotateX(180deg);
}

.leftCol{
    padding-right:1px;
    padding-left:25px;
    min-width:100px;
    max-width:100px;
}

.rowNumberColumn{
    padding-right:1px;
    padding-left:1px;
    min-width:50px;
    max-width:50px;
}

.fieldNameColumn{
    padding-right:1px;
    padding-left:1px;
    min-width:220px;
    max-width:220px;
}

.dataTypeColumn{
    padding-right:1px;
    padding-left:1px;
    min-width:150px;
    max-width:150px;
}

.keyColumn{
    padding-right:1px;
    padding-left:1px;
    min-width:80px;
    max-width:80px;
}

.sCD2Column{
    padding-right:1px;
    padding-left:1px;
    min-width:50px;
    max-width:50px;
}

.inViewColumn{
    padding-right:1px;
    padding-left:1px;
    min-width:50px;
    max-width:50px;
}

.middleLabelColumn{
    padding-right:1px;
    padding-left:1px;
    min-width:150px;
    max-width:150px;
}

.fieldMappingColumn{
    padding-right:1px;
    padding-left:1px;
    min-width:280px;
    max-width:280px;
}

.TableLabels{
    padding-right:1px;
    padding-left:1px;
    min-width:140px;
    max-width:140px;
}

.TableFields{
    padding-right:1px;
    padding-left:1px;
    min-width:420px;
    max-width:420px;
}

.ViewLabels{
    padding-right:1px;
    padding-left:1px;
    min-width:140px;
    max-width:140px;
}

.ViewFields{
    padding-right:1px;
    padding-left:1px;
    min-width:420px;
    max-width:420px;
}
</style>
