export class DWHField {
    constructor(DWHFieldId = '',
                DWHTableId = '',
                FieldName = '',
                DataType = '',
                Key = '',
                SCD2 = '',
                RowNumber = '',
                Revision = '',
                FieldIncludedInView) {
        this.DWHFieldId = DWHFieldId;
        this.DWHTableId = DWHTableId;
        this.FieldName = FieldName;
        this.DataType = DataType;
        this.Key= Key;
        this.SCD2 = SCD2;
        this.RowNumber = RowNumber;
        this.Revision = Revision;
        this.FieldIncludedInView = FieldIncludedInView;
    }
}








