import HandleResponseService from "./HandleResponseService";

export default class EnvironmentService {

    static storeEnvironment(environment){
        return axios.post('/api/environment', environment).then(response => {
            return HandleResponseService.handleResponse(response);
        }).catch((error) => {
            return HandleResponseService.handleError(error);
        });
    }

    static isLoggedIntoDWH(environmentid){
        return axios.get('/api/dwhdatabase/isloggedin/' + environmentid).then(response => {
            return HandleResponseService.handleResponse(response);
        }).catch((error) => {
            return HandleResponseService.handleError(error);
        });
    }

    static logIntoDWH(credentials){
        return axios.post('/api/dwhdatabase/login', credentials).then(response => {
            return HandleResponseService.handleResponse(response);
        }).catch((error) => {
            return HandleResponseService.handleError(error);
        });
    }
}
