export class StageDeleteModel {
    constructor(DWHTableId = '',
                DWHTableName = '',
                DWHTableStageBridgeName = '') {
        this.DWHTableId = DWHTableId;
        this.DWHTableName = DWHTableName;
        this.DWHTableStageBridgeName = DWHTableStageBridgeName;
    }
}








