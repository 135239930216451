export class StageDefinition {
    constructor(StageDefinitionId = '',
                EnvironmentId = '',
                Revision = '',
                StageName = '',
                SourceType = '') {
        this.StageDefinitionId = StageDefinitionId;
        this.EnvironmentId = EnvironmentId;
        this.Revision = Revision;
        this.StageName = StageName;
        this.SourceType = SourceType;
    }
}








