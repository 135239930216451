export default class HandleResponseService {

    static handleResponse(response){
        console.log('Response received:');
        console.log(response);
        if(response.status === 200){return response}
        // Create
        var wrongresponse = [];
        wrongresponse.data = [];
        wrongresponse.data.Success = false;
        wrongresponse.data.Message = 'The server did not respond correctly. See the console for more info.';
        return wrongresponse;
    }

    static handleError(error){
        console.log('An error occured:');
        console.log(error);
        // Create error response for the website.
        var response = [];
        response.data = [];
        response.data.Success = false;
        response.data.Message = 'An error occured trying to reach the server. See the console for more info.';
        return response;
    }
}
